import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContexts";
import { Link, useParams } from "react-router-dom";
import { Field, Form, Formik } from 'formik';
import axios from '../../api/axios';
import { Spinner } from "react-bootstrap";
import ScrollToBottom from 'react-scroll-to-bottom';
import Image from "./Image";
import moment from "moment";
import DeleteModal from "./DeleteModal";

function nl2br(str, is_xhtml = false) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

const Messagetext = () => {

    const param = useParams();
    const { userData, connectionState, sessionToken } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [messageUserData, setMessageUserData] = useState([]);
    const [chatMessage, setChatMessage] = useState([]);
    const [chatdata, setChatdata] = useState([]);
    const [loading, setLoading] = useState(true);

    const [dialogDetail, setDialogDetail] = useState({});
    const [usersList, setUsersList] = useState(null);
    const [loader, setLoader] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [popup, setPopup] = useState(false);
    const [messageId, setMessageId] = useState("");
    const QB = window.QB;

    const fileRef = useRef()

    // const scrollToBottom = () => {
    //     if (bottom && bottom.current !== null) {
    //         bottom.current.scrollIntoView({ behavior: "smooth" })
    //     }
    // }


    // useEffect(() => {
    //     scrollToBottom()
    // }, [chatdata]);
    useEffect(() => {
        if (connectionState) {
            setLoading(true);
            let params = {
                type: 3,
                token: sessionToken,
                occupants_ids: [param.id]
            };
            QB.chat.dialog.create(params, function (error, dialog) {
                if (error) {
                    console.log("********dialog error", error);
                }
                else {
                    setDialogDetail(dialog);
                    setChatdata([]);
                    setChatMessage([]);

                    chatMessages(dialog._id);
                }
            });

        }

        QB.chat.onMessageListener = onMessage;

        function onMessage(userId, message) {
            chatMessages(message.extension.dialog_id);
        }

    }, [sessionToken, param.id, connectionState]);



    const sendMessage = (data) => {
        let dialog = dialogDetail;
        let inputFile = data.file;
        if (inputFile) {
            let fileParams = {
                name: inputFile.name,
                file: inputFile,
                type: inputFile.type,
                size: inputFile.size,
                public: false
            };
            QB.content.createAndUpload(fileParams, function (error, result) {
                if (!error) {
                    var message = {
                        token: sessionToken,
                        type: "chat",
                        body: data.message,
                        extension: {
                            save_to_history: 1,
                            dialog_id: dialog._id,
                            attachments: [{ id: result.id, type: result.content_type, uid: result.uid }]
                        }
                    };
                    let opponentId = QB.chat.helpers.getRecipientId(dialogDetail.occupants_ids, userData.chat_credentials.qb_id);
                    try {
                        message.id = QB.chat.send(opponentId, message);
                        if (message.id) {
                            let updatedMessage = [];
                            let item = { _id: message.id, message: message.body, sender_id: userData.chat_credentials.qb_id, attachments: [{ id: result.id, type: result.content_type, uid: result.uid }] };
                            updatedMessage.push(item, ...chatMessage);
                            setChatMessage(updatedMessage);
                            setLoader(false);
                            setImageUrl(null);

                        }
                    } catch (e) {

                        if (e.name === 'ChatNotConnectedError') {
                            console.log("*******ChatNotConnectedError");
                        }
                    }
                }
                else {
                    console.log("*****error", error);
                }
            });
        } else {
            let dialog = dialogDetail;
            let message = {
                token: sessionToken,
                type: "chat",
                body: data.message,
                extension: {
                    save_to_history: 1,
                    dialog_id: dialog._id
                },
                markable: 1
            };

            let opponentId = QB.chat.helpers.getRecipientId(dialogDetail.occupants_ids, userData.chat_credentials.qb_id);
            try {
                message.id = QB.chat.send(opponentId, message);
                if (message.id) {
                    let updatedMessage = [];
                    let item = { _id: message.id, message: message.body, sender_id: userData.chat_credentials.qb_id, attachments: [] };
                    updatedMessage.push(item, ...chatMessage);
                    setChatMessage(updatedMessage);
                    setLoader(false);
                    setImageUrl(null);

                }
            } catch (e) {
                if (e.name === 'ChatNotConnectedError') {
                    console.log("*******ChatNotConnectedError");
                }
            }
        }


    };



    const chatMessages = (data) => {

        let dialogId = data;
        let params = {
            token: sessionToken,
            sort_desc: 'date_sent',
            chat_dialog_id: dialogId,
            limit: 1000,
            skip: 0
        };
        QB.chat.message.list(params, function (error, messages) {
            if (error) {
                console.log("******* chat message error", error);
            }
            else {
                setChatMessage(messages.items);
                setLoading(false);
                const senderId = messages?.items.map((item, index) => { return item.sender_id });
                let Ids = [];
                let filteredArray = senderId.filter((item,
                    index) => senderId.indexOf(item) === index);
                let finded = filteredArray.find(item => item == userData.chat_credentials.qb_id);
                if (finded) {
                    Ids.push(...filteredArray);
                } else {
                    Ids.push(...filteredArray, userData.chat_credentials.qb_id);
                }
                getUserDetails(Ids);
            }
        });
    };


    const getUserDetails = (data) => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        var formData = new FormData();
        if (data) {
            data.map((item, index) => {
                return formData.append(`qb_ids[${index}]`, item);
            })
        }
        axios.post('/users', formData, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setMessageUserData(Object.values(response.data));
                }

            }).catch(err => {
                console.log("******err ", err);
            });
    };

    const handleDeleteTrue = () => {
        setPopup(false);

    };

    useEffect(() => {
        let items = [];
        chatMessage.map(item => {
            const matchedProfile = messageUserData.find(data => data.chat_credentials.qb_id == item.sender_id);
            return items.push({ message: item, user: matchedProfile });
        });
        setChatdata(items);
    }, [chatMessage, messageUserData]);

    useEffect(() => {
        if (param.id) {
            if (dialogDetail.occupants_ids) {
                let opponentId = dialogDetail.occupants_ids;
                let filteredId = opponentId.find(item => item !== userData.chat_credentials?.qb_id);
                getUserDetail(param.id);
            }
        }

    }, [dialogDetail, param.id]);


    const getUserDetail = (id) => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
        };
        axios.get(`/user/${id}`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    let memberDetail = response.data;
                    setUsersList(memberDetail);
                }

            }).catch(err => {
                console.log("err ", err);
            });
    };


    const EnterPress = (e, submitForm) => {

        if (e.keyCode == 13 && (e.shiftKey == false)) {
            e.preventDefault();
            submitForm();
        }
    };

    return (
        <React.Fragment>
            <div className="col-lg-6">
                {loading ?
                    <div className="text-center mt-2">
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    ((!loading) && <React.Fragment>
                        <div className="card card-bottom-radius" >

                            <div className="card-header">
                                <h4 className="d-flex align-items-center">
                                    <Link to="/my-message" className="pe-3 d-flex align-items-center">
                                        <svg id="fi_9312240" fill="#000" enable-background="new 0 0 512 512" height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m411.5 281h-298c-13.81 0-25-11.19-25-25s11.19-25 25-25h298c13.81 0 25 11.19 25 25s-11.19 25-25 25z"></path></g><g><path d="m227.99 399.25c-6.08 0-12.18-2.21-16.99-6.67l-127.5-118.25c-5.14-4.77-8.05-11.48-8-18.5.05-7.01 3.04-13.69 8.25-18.39l131-118.25c10.25-9.25 26.06-8.44 35.31 1.81s8.44 26.06-1.81 35.31l-110.72 99.94 107.47 99.67c10.12 9.39 10.72 25.21 1.33 35.33-4.93 5.31-11.62 8-18.34 8z"></path></g></g></svg>
                                    </Link>
                                    <img src={usersList?.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="avatar-lg me-2" />{dialogDetail.name}</h4>
                            </div>
                            {usersList &&
                                <Formik
                                    initialValues={{
                                        message: '',
                                        file: null,
                                    }}
                                    onSubmit={(values, { resetForm }) => {

                                        if (values.message.trim() !== "" || values.file !== null) {
                                            setLoader(true);
                                            sendMessage(values);

                                            resetForm();
                                        }
                                    }} >
                                    {({ setFieldValue, submitForm }) => (
                                        < Form className="sticky-top"  >

                                            <div className="form-group card sticky-top card-top-radius">
                                                <div className="card-body px-3 py-4">
                                                    <div className="d-flex align-items-center">
                                                        <div className="UplaodIm me-2">

                                                            {imageUrl ? <img src={imageUrl} alt="" /> : <img src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/no-image.jpg"} alt="" />}
                                                            <input id="file" name="file" type="file" ref={fileRef} className=" border rounded my-3 col-8 p-1" onChange={(event) => {
                                                                const file = event.currentTarget.files[0];
                                                                var reader = new FileReader();

                                                                reader.onloadend = function () {
                                                                    console.log(reader.result);
                                                                }
                                                                reader.readAsDataURL(file);

                                                                reader.onloadend = function () {
                                                                    setImageUrl(reader.result);
                                                                }


                                                                setFieldValue("file", event.currentTarget.files[0]);
                                                            }} />
                                                            <label htmlFor="file" onClick={(e) => { e.preventDefault(); fileRef?.current?.click(); }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-camera"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                                                            </label>
                                                        </div>
                                                        <div className="flex-1">
                                                            <Field type="textarea" as="textarea" className="form-control " id="message" name="message" placeholder="Say something..." onKeyDown={(e) => EnterPress(e, submitForm)}
                                                            />
                                                        </div>

                                                        <div className="ps-2">
                                                            {loader ? <Spinner animation="border" variant="primary" /> : <button type="submit" className="BtnSnd" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>}

                            {chatdata.length > 0 ? <div className="h-100 w-100" >
                                <div className="card-body py-0 pb-3">
                                    <div className="maxHeight maxHeight700 scrollWidth" >

                                        {param.id && chatdata && chatdata.map((data) => {
                                            return <div className="mt-3 border-bottom" >
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src={data.user?.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="avatar-lg" />
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-0"><strong>{data.user?.display_name}</strong></h6>
                                                        <p className="mb-0 text-muted"> {moment(data.message?.created_at).format('MMM D, YYYY [at] h:mm A z')}</p>
                                                    </div>
                                                    <div>
                                                        {(data?.user?.id === userData?.id) && < div className="flex-end">
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { setPopup(true); setMessageId(data.message._id); }}><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className="ps-2 pe-2 pt-2">
                                                    <p dangerouslySetInnerHTML={{ __html: nl2br(data.message.message) }}></p>
                                                    <Image data={data.message.attachments}></Image>
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </div>
                            </div>

                                : <div className="card card-bottom-radius ">
                                    <div className="card-body">
                                        <h6 className="text-center">No message found</h6></div></div>
                            }
                        </div>
                    </React.Fragment>)
                }

            </div >
            {popup && <DeleteModal chatdata={chatMessage} setChatdata={(data) => setChatMessage(data)} QB={QB} popup={popup} setPopup={() => setPopup(false)} messageId={messageId} />}
        </React.Fragment >
    )
};
export default Messagetext;