import React, { useContext, useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { UserContext } from "../../../contexts/UserContexts";
import axios from "axios";
import { Spinner } from "react-bootstrap";


const mediaUpload = Yup.object().shape({
    file: Yup.mixed().required("This is a required"),
});


const MediaUpload = ({ eventId, menu_item_id, setMediaDetail }) => {
    const { userData } = useContext(UserContext);
    const [uploading, setuploading] = useState(false);
    const [image, setImage] = useState([]);
    const fileRef = useRef();
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");

    const uploadMedia = (values, actions) => {
        setuploading(true);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        var formData = new FormData();
        for (let key in values.file) {
            formData.append(`files[${[key]}]`, values.file[key]);
        }
        formData.append("vendor_id", userData.vendor_id);
        formData.append("menu_item_id", menu_item_id);

        if (values.uploadType === "image") {
            axios.post(`${process.env.REACT_APP_API_IMAGEUPLOAD_URL}/event/${eventId}/galleries`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe\
                    if (response.status == "200") {
                        setuploading(false);
                        toast.success("Media uploaded successfully");
                        setMediaDetail(response.data);
                        setImage('');
                        sendNotifiction();
                    }
                }).catch(err => {
                    console.log("err ", err);
                    toast.error("The files must be a file of type: jpeg, bmp, png, jpg, gif, webp.")
                    setuploading(false);
                });
        }
        if (values.uploadType === "video") {
            axios.post(`${process.env.REACT_APP_API_VIDEOUPLOAD_URL}/event/${eventId}/galleries`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe
                    if (response.status == "200") {
                        setuploading(false);
                        toast.success("Media uploaded successfully");
                        sendNotifiction();
                        setImage('');
                        setMediaDetail(response.data);
                    }
                }).catch(err => {
                    toast.error("The files must be a file of type: video/mp4, video/ogg, video/quicktime, application/octet-stream")
                    console.log("err ", err);
                    setuploading(false);
                });
        }

    };

    const sendNotifiction = () => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
            event_id: eventId,
            menu_item_id: menu_item_id,
            user_id: userData.id
        };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gallery/upload-notification`, payload)
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    console.log("********response noti", response);
                }
            }).catch(err => {
                console.log("err ", err);
            });
    }

    const onImageChange = async (e, setFieldValue) => {
        let images = [];
        if (e.target.files) {
            // setImage(URL.createObjectURL(e.target.files[0]));
            images.push(...image, ...Array.from(e.target.files));
            setImage(images);
            setFieldValue("file", images);
        }
    };
    const removeImage = (data, key, setFieldValue) => {
        let deleteImage = image.filter((item, index) => index !== key);
        setImage(deleteImage);
        setFieldValue("file", deleteImage);
    };
    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    file: "",
                    uploadType: "image"

                }}
                validationSchema={mediaUpload}
                onSubmit={(values, actions) => {
                    uploadMedia(values, actions);
                    actions.setSubmitting(false);
                    setuploading(true);
                    // actions.resetForm();

                }} >
                {({ errors, touched, setFieldValue, values }) => (
                    < Form >
                        <div className='row my-auto  p-4 justify-content-center border-bottom mb-4'>
                            <div className="col-md-5">
                    
                                <div className="row">

                                

                        <div className="col">
                                <Field className="btn btn-secondary w-100 h-100 py-2" as="select" name="uploadType" onChange={(e) => setFieldValue('uploadType', e.target.value)}>
                                    <option value="image" >Image</option>
                                    <option value="video">Video</option>

                                </Field>
                            </div>
                            <div className="col">
                                <div className="row text-center ">
                                    <div className="col-12 ">
                                        <label className="btn btn-secondary py-2 d-flex align-items-center justify-content-center h-100" htmlFor="file" onClick={(e) => { e.preventDefault(); fileRef?.current?.click(); }}>
                                        <svg height="25px" fill="#000" viewBox="0 0 512 512.04267" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="m229.347656 458.667969c-49.984375 0-90.667968-40.683594-90.667968-90.667969v-303.59375l-103.058594 23.59375c-25.472656 6.699219-40.746094 32.960938-34.007813 58.667969l88.363281 329.917969c5.867188 21.335937 25.472657 35.457031 46.507813 35.457031 3.945313 0 7.957031-.492188 11.925781-1.535157l215.039063-51.839843zm0 0"/><path d="m277.347656 192c23.53125 0 42.667969-19.136719 42.667969-42.667969s-19.136719-42.664062-42.667969-42.664062-42.667968 19.132812-42.667968 42.664062 19.136718 42.667969 42.667968 42.667969zm0 0"/><path d="m512.015625 64c0-35.347656-28.652344-64-64-64h-213.335937c-35.347657 0-64 28.652344-64 64v298.667969c0 35.347656 28.652343 64 64 64h213.335937c35.347656 0 64-28.652344 64-64zm-277.335937-21.332031h213.335937c11.773437 0 21.332031 9.554687 21.332031 21.332031v137.367188l-16.214844-16.214844c-14.613281-14.613282-38.3125-14.613282-52.90625 0l-101.546874 101.546875-26.878907-26.878907c-14.613281-14.613281-38.3125-14.613281-52.90625 0l-5.546875 5.546876v-201.367188c0-11.777344 9.558594-21.332031 21.332032-21.332031zm0 0"/></svg>
                                            <span className="ps-2">Browse</span>
                                        </label>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-12 py-3">
                            <div className="row justify-content-center">
                                        {values.uploadType === 'image' && <React.Fragment>
                                            <input multiple type="file" accept="image/*" ref={fileRef} onChange={(e) => onImageChange(e, setFieldValue)} className="form-control" id="file" style={{ display: "none" }} />
                                            {image && image?.map((pic, index) => {
                                                return <div className="col-md-3 mb-3"><span className=" position-relative d-block border rounded-10 p-2">< img alt="" className="img-fluid rounded-10" src={URL.createObjectURL(pic) || process.env.REACT_APP_PUBLIC_URL + "/assets/images/no-image.jpg"} />
                                             
                                                <span className="position-absolute closeico" onClick={() => { removeImage(pic, index, setFieldValue); }}
                                                        style={{ top: "10px", right: "10px", cursor: "pointer" }}>
                                                    <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m479.8 512a31.988 31.988 0 0 1 -22.769-9.432l-190.293-190.288a15.184 15.184 0 0 0 -21.475 0l-190.288 190.288a32.2 32.2 0 0 1 -45.543-45.542l190.288-190.289a15.183 15.183 0 0 0 0-21.474l-190.288-190.288a32.2 32.2 0 1 1 45.542-45.543l190.289 190.288a15.184 15.184 0 0 0 21.476 0l190.287-190.288a32.2 32.2 0 0 1 45.543 45.543l-190.289 190.288a15.185 15.185 0 0 0 0 21.474l190.289 190.288a32.2 32.2 0 0 1 -22.769 54.975z"/></svg>
                                                    </span>
                                                </span></div>
                                            })}
                                        </React.Fragment>}
                                    </div>
                                    {values.uploadType === 'video' && <React.Fragment>
                                        <div>
                                        <input multiple type="file" accept="video/*" ref={fileRef} onChange={(e) => onImageChange(e, setFieldValue)} className="form-control" id="file" style={{ display: "none" }} />
                                        {image && image?.map((pic, index) => {
                                            return <span className="position-relative d-block border rounded-10  p-2" >
                                                <video className="img-fluid img-cover rounded-10" src={URL.createObjectURL(pic) || process.env.REACT_APP_PUBLIC_URL + "/assets/images/no-image.jpg"} onClick={() => document.getElementById('file').click()} />
                                                
                                                <span className="position-absolute closeico" onClick={() => { removeImage(pic, index, setFieldValue); }}
                                                    style={{ top: "10px", right: "10px", cursor: "pointer" }}>

                                                <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m479.8 512a31.988 31.988 0 0 1 -22.769-9.432l-190.293-190.288a15.184 15.184 0 0 0 -21.475 0l-190.288 190.288a32.2 32.2 0 0 1 -45.543-45.542l190.288-190.289a15.183 15.183 0 0 0 0-21.474l-190.288-190.288a32.2 32.2 0 1 1 45.542-45.543l190.289 190.288a15.184 15.184 0 0 0 21.476 0l190.287-190.288a32.2 32.2 0 0 1 45.543 45.543l-190.289 190.288a15.185 15.185 0 0 0 0 21.474l190.289 190.288a32.2 32.2 0 0 1 -22.769 54.975z"/></svg>
                                                </span>
                                            </span>
                                        })}
                                        </div>
                                    </React.Fragment>}
                            </div>
                            
                            <div className="col-12">
                                {uploading ? <Spinner animation="border" variant="primary" /> : <button type="submit" className="BtnSnd btn btn-green w-100 py-2" >
                                    Post
                                </button>
                                }
                            </div>
                            </div>
                            </div>
                        </div></Form>
                )
                }
            </Formik >
        </React.Fragment >
    );
}
export default MediaUpload;