import axios from "../api/axios";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContexts";
import { Link } from "react-router-dom";

export default function Rightside() {
    const { setExperienceCategoryId, userData, setbreadcrumb } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [experienceCategory, setExperienceCategory] = useState([]);
    const [discussionItems, setDiscussionItems] = useState([]);

    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,
        };
        axios.get('/user/event-categories', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe

                setExperienceCategory(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token]);
    useEffect(() => {
        let payload = {
            api_token: token,
            vendor_id: userData?.vendor_id,

        };
        axios.get('/user/comment-boards', { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe

                setDiscussionItems(response.data);
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token]);

    return (
        <div className="col ps-lg-4">
            <div className="card mb-4">
                <div className="card-header text-center border-bottom-0">
                    Member Center
                </div>
                <div className="card-body pt-0 px-0">
                    <ul className="memberList memberListBorder">
                        {experienceCategory && experienceCategory.length > 0 && experienceCategory.map((category, index) => {
                            return <li key={index}>
                                <Link to={`forums/category/${category.id}`} className="mb-0" onClick={(e) => { setExperienceCategoryId(category.id); setbreadcrumb(category.name); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', category.name); localStorage.setItem(process.env.REACT_APP_NAME + '_forumCat', category.name) }}><strong> {category.name}</strong></Link>
                            </li>
                        })}

                    </ul>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-header text-center border-bottom-0">
                    Discussion Board
                </div>
                <div className="card-body pt-0 px-0">
                    <ul className="memberList memberListBorder">
                        {discussionItems && discussionItems.map((items, index) => {
                            return <li key={index}>
                                <Link to={`discussion-board/${items.dialog_id}`} onClick={() => { setbreadcrumb(items.name); localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', items.name); localStorage.setItem(process.env.REACT_APP_NAME + '_discussionName', items.name); }}>
                                    <h6 className="mb-0"><strong>{items.event_name}</strong></h6>
                                    <p className="mb-0">{items.name}</p>
                                </Link>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </div >
    )
}